@tailwind base;
@tailwind components;
@tailwind utilities;

.hidden-tab{
    @apply hidden
}

.active-tab{
    @apply flex flex-col
}